import type { ILayoutRecord } from './DataObject.Layout.ts';
import type { Procedure } from 'o365-modules';
import { API, getOrCreateProcedure, configurableRegister } from 'o365-modules';

/** Helper singleton class for validating and retrieving layouts on the current app */
export default class LayoutUpdatesRetriever {
    /** Private variable to enforce singelton */
    static #isInternalConstructing: boolean;
    /** The singleton instance */
    private static checker: LayoutUpdatesRetriever;
    /** Get or create the singleton instance */
    static getInstance() {
        if (!LayoutUpdatesRetriever.checker) {
            LayoutUpdatesRetriever.#isInternalConstructing = true;
            LayoutUpdatesRetriever.checker = new LayoutUpdatesRetriever();
            LayoutUpdatesRetriever.#isInternalConstructing = false;
        }
        return LayoutUpdatesRetriever.checker;
    }

    /** Procedure for fetching layouts */
    private _fetchProc: Procedure<{
        App_ID: string,
        Register_ID?: number,
        Layout_IDs?: number[][],
        Action: 'check' | 'retrieve',
    }>; 

    private _layouts: Record<string, LayooutsMap> = {};
    private _getAppLayoutsPromises: Record<string, Promise<LayooutsMap> | undefined> = {};

    constructor() {
        if (!LayoutUpdatesRetriever.#isInternalConstructing) {
            throw new TypeError('LayoutUpdatesRetriever is not constructable, use LayoutUpdatesRetriever.getAriaIndexParser()');
        }

        this._fetchProc = getOrCreateProcedure({
            id: '_procFetchLayouts',
            procedureName: 'sstp_O365_GetAppLayouts2'
        }, false);
    }

    /** Retrieve the layouts meta info for the current app */
    getAppLayouts(appId: string) {
        if (this._getAppLayoutsPromises[appId]) { return this._getAppLayoutsPromises[appId]; }

        this._getAppLayoutsPromises[appId] = new Promise(async (res, rej) => {
            try {
                const respone = await this._fetchProc.execute({
                    App_ID: appId,
                    Register_ID: configurableRegister.id,
                    Action: 'check'
                });

                this._layouts[appId] = {};
                respone.Table.forEach((layout: ILayoutsCheckResponse) => {
                    if (!this._layouts[appId][layout.DataObject_ID]) { this._layouts[appId][layout.DataObject_ID] = {}; }
                    layout.Updated = layout.Updated.split('.')[0]; // Procedures return wrong datetime format, drop it for now as a temp fix
                    this._layouts[appId][layout.DataObject_ID][layout.ID] = layout;
                });

                res(this._layouts[appId]);
            } catch (ex) {
                rej(ex);
            }
        });

        return this._getAppLayoutsPromises[appId];
    }

    async getLayoutByIds(ids: number[][], appId: string): Promise<ILayoutRecord[]> {
        if (ids == null || ids.length === 0) { return []; }
        const layout = await this._fetchProc.execute({
            App_ID: appId,
            Layout_IDs: ids,
            Register_ID: configurableRegister.id,
            Action: 'retrieve'
        });

        return layout?.Table ?? [];
    }
}

type LayooutsMap = Record<string, Record<number, ILayoutsCheckResponse>>;

interface ILayoutsCheckResponse {
    ID: number;
    Updated: string;
    Person_ID?: number;
    OrgUnit_ID?: number;
    DataObject_ID: string;
    Default: boolean;
};

/** Helper class for retreiving layout sharing capabilities for current user */
export class SharingCapabilitiesChecker {
    /** Private variable to enforce singelton */
    static #isInternalConstructing: boolean;
    /** The singleton instance */
    private static checker: SharingCapabilitiesChecker;
    /** Get or create the singleton instance */
    static getInstance() {
        if (!SharingCapabilitiesChecker.checker) {
            SharingCapabilitiesChecker.#isInternalConstructing = true;
            SharingCapabilitiesChecker.checker = new SharingCapabilitiesChecker();
            SharingCapabilitiesChecker.#isInternalConstructing = false;
        }
        return SharingCapabilitiesChecker.checker;
    }

    private _getCapabilitiesPromise?: Promise<ISharingCapabilityRecord[]>;
    private _canSetDefaultLayouts?: Promise<boolean>;

    constructor() {
        if (!SharingCapabilitiesChecker.#isInternalConstructing) {
            throw new TypeError('LayoutUpdatesRetriever is not constructable, use LayoutUpdatesRetriever.getAriaIndexParser()');
        }
    }

    canSetDefaultLayouts() {
        if (!this._canSetDefaultLayouts) {
            const promise = new Promise<boolean>(async (res, rej) => {
                try {
                    const response = await API.requestPost('/api/data', JSON.stringify({
                        distinctRows: true,
                        fields: [
                            { name: 'Role_ID' },
                            { name: 'IdPath' },
                            { name: 'AccessIdPath' },
                        ],
                        loadRecents: false,
                        maxRecords: 1,
                        operation: 'retrieve',
                        skip: 0,
                        viewName: 'sviw_System_MyCapabilities',
                        whereClause: `[Capability] = 'Can set default layout'`
                    }));

                    res(response.length > 0);
                } catch (ex) {
                    console.error(ex);
                    rej(false);
                }
            });
            this._canSetDefaultLayouts = promise;
        }

        return this._canSetDefaultLayouts!;
    }

    /** Retrieve the layouts sharing capabilities for the current user */
    getCapabilities() {
        if (this._getCapabilitiesPromise) { return this._getCapabilitiesPromise; }
        this._getCapabilitiesPromise = new Promise(async (res, rej) => {
            try {
                const response = await API.requestPost('/api/data', JSON.stringify({
                    distinctRows: true,
                    fields: [
                        { name: 'Role_ID' },
                        { name: 'IdPath' },
                        { name: 'AccessIdPath' },
                    ],
                    loadRecents: false,
                    maxRecords: -1,
                    operation: 'retrieve',
                    skip: 0,
                    viewName: 'sviw_System_MyCapabilities',
                    whereClause: `[Capability] = 'Can Share NT Layouts'`
                }));

                res(response);
            } catch (ex) {
                rej(ex);
            }
        });

        return this._getCapabilitiesPromise;
    }

    /** Returns true if user can share for the current Id path */
    async canShareInPath(pIdPath: string) {
        const data = await this.getCapabilities();

        return data.some(row => {
            const idPath = row.IdPath.replace('%', '');
            return pIdPath.startsWith(idPath);
        });
    }

    async canShare() {
        const data = await this.getCapabilities();
        return data.length > 0;
    }

    /** Check if an OrgUnit has a default layout */
    async orgUnitHasNoDefault(pOrgUnitId: number, pDataObjectId: string, pAppId: string) {
        try {
            const whereClauseArr = [
                `[OrgUnit_ID] = ${pOrgUnitId}`,
                `[Default] = 1`,
                `[App_ID] = '${pAppId}'`,
                `[DataObject_ID] = '${pDataObjectId}'`,
                `[Person_ID] IS NULL`,

            ];
            if (configurableRegister.id) {
                whereClauseArr.push(`[Register_ID] = ${configurableRegister.id}`)
            } else {
                whereClauseArr.push('[Register_ID] IS NULL');
            }
            const whereClause = whereClauseArr.join(' AND ');
            const data = await API.requestPost('/api/data/stbv_O365_Layouts', JSON.stringify({
                distinctRows: true,
                fields: [
                    { name: 'ID' },
                    { name: 'OrgUnit_ID' },
                    { name: 'Default' },
                ],
                loadRecents: false,
                maxRecords: -1,
                operation: 'retrieve',
                skip: 0,
                viewName: 'stbv_O365_Layouts',
                whereClause: whereClause,
            }));

            return data.length === 0;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }
}

const procSetLayoutAsHidden = getOrCreateProcedure<{
    ID: number,
    Person_ID?: number
}>({
    id: '_procSetLayoutAsHidden',
    procedureName: 'sstp_O365_Layouts_SetAsHidden',
}, false);

const procUnsetLayoutAsHidden = getOrCreateProcedure<{
    ID: number,
    Person_ID?: number
}>({
    id: '_procUnsetLayoutAsHidden',
    procedureName: 'sstp_O365_Layouts_RemoveHidden',
}, false);

interface ISharingCapabilityRecord {
    Role_ID: number;
    IdPath: string;
    AccessIdPath: string;
};

export { procSetLayoutAsHidden, procUnsetLayoutAsHidden };